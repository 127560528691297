import React, { Component } from 'react';
import QrReader from 'react-qr-reader';
import axios from 'axios';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Container from 'react-bootstrap/Container';

import './App.scss';
import config from './config';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      result: '',
      type: 'dark',
      loading: false,
      modal: false,
      disabilities: [],
    };
  }

  handleScan = async (data) => {
    if (data) {
      const newState = {
        ...this.state,
        data,
      };
      this.setState(newState);

      this.handleSubmit(data);
    }
  };

  handleError = (err) => {
    this.setState({
      ...this.state,
      result: err,
    });
  };

  handleSubmit = (data) => {
    this.setState({
      ...this.state,
      result: 'Loading...',
      loading: true,
    });

    axios({
      url: `${config.BASE_URL}/beta/festivalrelawan/ticket-check`,
      method: 'POST',
      data: {
        data,
      },
      headers: {
        'Content-Type': 'application/json',
      },
      validateStatus: (status) => status >= 200 && status < 300,
    })
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          if (response.data.result.disabilities.length > 0) {
            this.setState({
              ...this.state,
              result: 'BUTUH PENDAMPING',
              type: 'warning',
              modal: true,
              disabilities: response.data.result.disabilities,
            });
          } else {
            this.setState({
              ...this.state,
              result: 'SUKSES',
              type: 'success',
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          this.setState({
            ...this.state,
            result: error.response.data.message,
            type: 'danger',
          });
        } else {
          this.setState({
            ...this.state,
            result: error.message,
            type: 'danger',
          });
        }
      })
      .finally(() => {
        setTimeout(() => {
          this.setState({
            ...this.state,
            loading: false,
          });
        }, 2000);
      });
  };

  render() {
    return (
      <main>
        <QrReader
          delay={300}
          onError={this.handleError}
          onScan={(data) => {
            if (!this.state.loading) {
              this.handleScan(data);
            }
          }}
          style={{ width: '100%' }}
        />
        <Container className="text-center">
          <p className="font-weight-bold mt-3 data">{this.state.data}</p>
          <p className={`font-weight-bold mt-3 text-${this.state.type}`}>
            {this.state.result}
          </p>
        </Container>
        <Modal show={this.state.modal}>
          <Modal.Header closeButton>
            <Modal.Title>PERHATIAN!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>
              Relawan ini memiliki disabilitas{' '}
              {this.state.disabilities.join(', ')}, sehingga membutuhkan
              pendamping.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() =>
                this.setState({
                  ...this.state,
                  loading: false,
                  modal: false,
                  disabilities: [],
                })
              }>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </main>
    );
  }
}

export default App;
